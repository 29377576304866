import request from '@/utils/requestV2'
const qs = require('qs')

// 查询单个合同的基本信息
export function getContractById (data) {
  return request({
    url: '/ooh-displace/v1/contract/getContractById',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询合同列表
export function listContractPage (data) {
  return request({
    url: '/ooh-displace/v1/contract/listContractInfo',
    method: 'get',
    params: data
  })
}
// 查询合同的商品明细列表
export function listContractDetail (data) {
  return request({
    url: '/ooh-displace/v1/contract/listContractDetail',
    method: 'get',
    params: data
  })
}
// 查询合同的UNION资源明细
export function listOrderDetail (data) {
  return request({
    url: '/ooh-displace/v1/contract/listOrderDetail',
    method: 'get',
    params: data
  })
}
// 查询合同的资源的折扣列表
export function listDiscount (data) {
  return request({
    url: '/ooh-displace/v1/contract/listDiscount',
    method: 'get',
    params: data
  })
}
// 查询合同附件
export function listContractFile (data) {
  return request({
    url: '/ooh-displace/v1/contract/listContractFile',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除合同附件
export function deleteContractFile (data) {
  return request({
    url: '/ooh-displace/v1/contract/deleteContractFile',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 增加合同下的一个商品
export function addProduct (data) {
  return request({
    url: '/ooh-displace/v1/contract/addProduct',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除合同的商品
export function deleteProduct (data) {
  return request({
    url: '/ooh-displace/v1/contract/deleteProduct',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 编辑合同下的一个商品
export function updateProduct (data) {
  return request({
    url: '/ooh-displace/v1/contract/updateProduct',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询合同的服务费列表
export function listService (data) {
  return request({
    url: '/ooh-displace/v1/contract/listService',
    method: 'get',
    params: data
  })
}

// 查看合同变更日志
export function listLogDetail (data) {
  return request({
    url: '/ooh-displace/v1/contract/listLogDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
