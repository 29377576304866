<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col  :xs="24" :sm="12" :md="4" class="m-b-5">
        <DatePicker size="small" placeholder="合同开始时间"
          v-model="query.startDate" @on-change="query.startDate=$event"
          style="width:100%"></DatePicker>
      </i-col>
      <i-col  :xs="24" :sm="12" :md="4" class="m-b-5">
        <DatePicker size="small" placeholder="合同结束时间" v-model="query.endDate" @on-change="query.endDate=$event" style="width:100%"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Select
          v-model="query.status"
          size="small"
          placeholder="合同状态"
          clearable
        >
          <Option
            v-for="(status, index) in contractStatusArray"
            :key="index"
            :value="status.dictKey"
            >{{ status.dictValue }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="6">
        <i-input
          size="small"
          v-model="query.queryString"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { getDisplaceDictList } from '@/api/displace/dict'
import { listContractPage } from '@/api/displace/contract'
import { formatDisplaceStatus } from '@/utils/tagStatus'

export default {
  props: {
    isManage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      contractStatusArray: [],

      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: '',
        status: null,
        queryString: '',
        userId: null
      },
      tableData: [],
      columnManagedata: [
        { title: '合同编码', key: 'code' },
        { title: '合同名称', key: 'name' },
        {
          title: '合同类型',
          align: 'center',
          render: (h, params) => {
            if (params.row.type === 2) {
              return h('tag', { props: { color: 'blue' } }, params.row.typeName)
            } else {
              return h('span', params.row.typeName)
            }
          }
        },
        {
          title: '商家名称',
          key: 'businessName'
        },
        { title: '置换金额', key: 'amount' },
        { title: '创建人', key: 'userName' },
        { title: '创建时间', key: 'createTime' },
        { title: '签约日期', key: 'signDate' },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatDisplaceStatus(
              h,
              params.row.status,
              params.row.statusName
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  // style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$emit('on-detail', params.row.id)
                    }
                  }
                },
                '详情'
              )
            ])
          }
        }
      ]

    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getDisplaceDictList({ code: 'contract_status' }).then(res => {
        this.contractStatusArray = res
      })

      if (!this.isManage) {
        this.query.userId = this.$store.getters.token.userInfo.userId
        this.columnManagedata.splice(5, 1)
      }

      this.loadTableData()
    },
    loadTableData () {
      listContractPage(this.query).then((response) => {
        this.total = response.total
        this.tableData = response.records
        this.query.pageNumber = response.current
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.loadTableData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.loadTableData()
    }
  }
}
</script>
