import request from '@/utils/requestV2'

// 获取字典表
export function getDisplaceDictList (data) {
  return request({
    url: '/ooh-displace/v1/dict/getList',
    method: 'get',
    params: data
  })
}
